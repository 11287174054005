import React from 'react'
import estudionew from '../../utils/estudionew.jpg'

function Hall() {
  return (
    <section id='hall'>
       <h1 className="titles">Sala</h1>
         <div>
          <article className="aside-hall">
          <p className="texto-hall"> La planta alta de <b>La nube</b> brinda más de 100 metros para el
            desarrollo de rodajes, sesiones fotográficas, ensambles, eventos,
            streaming y más.</p>
        
          <img src={estudionew} alt="estudio-nube-factory" className="img-hall"/>
          </article>

        </div>  
      
    </section>
  );
}
export default Hall