import React from 'react'

function MadeInNube() {
  return (
    <section id='madeIn'>
          <h1 className="titles lanzamientos">¡Lanzamientos!</h1>
        <div className="spotify">
        <iframe 
        src="https://open.spotify.com/embed/playlist/4x4o5uGuY0qy1Fid2wVvI6?utm_source=generator&theme=0" 
        width="100%" 
        height="480" 
        className="iframe-spotify"
        allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
        loading="lazy"
        title="Spotify player">
        </iframe>
          <button className="button-spotify"><a href="https://open.spotify.com/playlist/4x4o5uGuY0qy1Fid2wVvI6?go=1&sp_cid=eec33aa84b845bf466aa0c35f4db476a&utm_source=embed_player_p&utm_medium=desktop&nd=1">SPOTIFY</a></button>
        </div>
        <div className="spotify">
        <iframe
        src="https://www.youtube.com/embed/DBXH9jJRaDk?autoplay=0&fs=1&iv_load_policy=1&showinfo=1&rel=1&cc_load_policy=1&start=0&end=0&vq=hd1080&origin=https://www.youtube.com/watch?v=ISEuDOpJMOw&list=PLP3-aBPVwfHQM19KAzwtxg_k5dyX5wlql"
        width="100%" 
        height="480"
        title="YouTube Play"
        className="iframe-youtube"
        loading="lazy"
        >
        </iframe>
        <button className="button-spotify"><a href="https://www.youtube.com/watch?v=ISEuDOpJMOw&list=PLP3-aBPVwfHQM19KAzwtxg_k5dyX5wlql">YOUTUBE</a></button>
        </div>
    </section>
  );
}

export default MadeInNube