import React from 'react'

function ServiceCard({service}) {
  return (
    <div className="cardServices">
      <img src={service.img} alt="servicio-imagen" className="imagen-servicio" />
      <h5>
        {service.title}
    </h5>
      <p>
        {service.description}
      
        
      </p>
    </div>
  );
}

export default ServiceCard