import React from 'react'

const AboutUs = () => {
  return (
    <div id="aboutUs">
      <p className="texto-about">Espacio de desarrollo integral y acompañamiento de producciones audiovisuales</p>
      <article className="location">
      <span className='span'><i class="bi bi-geo-alt-fill"></i>Villa Crespo, CABA</span>
      </article>
    </div>
  )
}

export default AboutUs
