import React, { useState } from "react";

const images = [
  {
    id: 1,
    src: require("../../utils/foto1.jpg")
},
{
    id: 2,
    src: require("../../utils/foto2.jpg")
},
{
    id: 3,
    src: require("../../utils/foto3.jpg")
}, {
  id: 4,
  src: require("../../utils/foto4.jpg")
}
];

const Study = () => {
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handlePrev = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };
  
  const handleNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  return (
    <div id="study">
      <h1 className="titles">Estudio</h1>
      <p className='textos'> Contamos con una sala de trabajo amplia y equipada para el
            desarrollo de artistas, productores, técnicos y beatmakers. Tanto
            nuestros equipos como las personas que trabajamos en <b>La nube</b>{" "}
            nos dedicamos a asegurar un buen workflow en toda tu sesión.</p>
    <div className="carrousel">
      <button className='arrow1' onClick={handlePrev}><i class="bi bi-arrow-left"></i></button>
      
      <div>
      <img
        src={images[currentImageIndex].src}
        alt={images[currentImageIndex].alt}
        className="imagestat"
      />
    </div>
    <button className='arrow2' onClick={handleNext}><i class="bi bi-arrow-right"></i></button>
    </div>
    </div>
  )
}

export default Study;