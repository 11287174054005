import React from "react";
import ServiceCard from "../../commons/ServiceCard";
import services from "../../utils/services.js";

function Services() {
  return (
    <div id="services" >
     <div>
          <h1 className="titles">Servicios</h1>
        </div>
      <div className="services-grid">
        {services.map((service, i) => {
          return <ServiceCard service={service} key={i} />;
        })}
      </div>
    </div>
  );
}

export default Services;
