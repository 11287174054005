import React from 'react'
import './home.css'
import logoColor from '../../utils/logos/logoColor.png'

function Home() {
  return (
    <div id='home'>
    <article className="home" >
          <img src={logoColor} alt="logo-la-nube-factory" className="logo-home" />
          {/* <article>
          <h1>La Nube Factory</h1>
          <p className="texto-home">
            Tu espacio de desarrollo integral
            y acompañamiento de producciones audiovisuales.{"\n"} Estamos
            ubicados en Villa Crespo, CABA.
          </p>
          </article> */}
  
    
    </article>
    </div>
  );
}

export default Home