import React, { useState } from 'react'
import logo from "../../utils/logos/logoColor.png"

function Navb() {

  const [isNavExpanded, SetIsNavExpanded] = useState(false)

  const abrirMenu = () => {
    SetIsNavExpanded(!isNavExpanded)
  }

  const cerrarMenu = () => {
    SetIsNavExpanded(false)
  }
  return (
    <header className="menu">
     <a className="navbar-brand" href="#home"><img src={logo} alt="la-nube-factory" className="logo-menu"/></a> 
     <button className="hamburger" onClick={abrirMenu}><i class="bi bi-list"></i></button>
    <nav className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }>
      <ul className="navbar">
      <button  className="close-menu" onClick={cerrarMenu}><i class="bi bi-x-lg"></i></button>
         <li className="nav-item" onClick={cerrarMenu}><a className="nav-link" aria-current="page"href="#aboutUs">Nosotros</a></li>
         <li className="nav-item" onClick={cerrarMenu}><a className="nav-link" aria-current="page" href="#hall">Sala</a></li>
         <li className="nav-item" onClick={cerrarMenu}><a className="nav-link" aria-current="page"href="#services">Servicios</a></li>
         <li className="nav-item" onClick={cerrarMenu}><a className="nav-link" aria-current="page" href="#study">Estudio</a></li>
         <li className="nav-item" onClick={cerrarMenu}><a className="nav-link" aria-current="page" href="#madeIn">Lanzamientos</a></li>
         <li className="nav-item" onClick={cerrarMenu}><a className="nav-link" aria-current="page" href="#contact">Contacto</a></li>
       </ul>
    </nav>
  </header>
    // <nav className="navbar">
    //     <a className="navbar-brand" href="#home">
    //       <img
    //         src={logo}
    //         alt="La-nube-factory"
    //         style={{ width: "180px", heigth: "250px", margin: " 2rem 3rem", borderRadius: "5%"}}
    //       />
    //     </a>
    //     <button className="btn-navbar"><i class="bi bi-list"></i></button>
    //       <ul className="navbar-nav">
    //         <li className="nav-item">
    //           <a className="nav-link active" aria-current="page" href="#aboutUs">
    //            Nosotros
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a
    //             className="nav-link active" aria-current="page" href="#services">
    //             SERVICIOS
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link active" aria-current="page" href="#study">
    //             ESTUDIO
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link active" aria-current="page" href="#hall">
    //             SALA
    //           </a>
    //         </li>
    //         <li className="nav-item"><a className="nav-link active" aria-current="page" href="#madeIn">Lanzamientos</a></li>
    //         <li className="nav-item">
    //           <a className="nav-link active" aria-current="page" href="#contact">
    //             CONTACTO
    //           </a>
    //         </li>
    //       </ul>
    // </nav>

  );
}

export default Navb;