

const ContactForm = () => {

  return (
    <div id="contact" >
    <form action="https://formsubmit.co/062ee739ad45fc3929736d1f2128f93a" method="POST" className="contact">
    <label for="name">Nombre</label>
    <input type="text" id="name" name="name"/>
    <label for="email">Correo</label>
    <input type="email" id="email" name="email" required/>
    <label for="comments">Servicios/Comentarios</label>
    <textarea name="comments" cols="15" rows="5"/>
    <button type="sumbit" value="Enviar" className="sendButton">
      Enviar
    </button>

    <input type="hidden" name="_next" value="http://www.lanubefactory.com.ar"/>
    <input type="hidden" name="_captcha" value="false"/>
    </form>
    </div>
  )
}

export default ContactForm