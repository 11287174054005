import React from 'react'
import logo from '../../utils/logos/logoColor.png'

function Footer() {
  return (
    <footer id="footer" className="footer">
    <img src={logo} alt="la-nube-factory" className="logo-footer" />
    <aside className="redes">
      <a href="https://www.instagram.com/lanubefactory/?hl=es"><i class="bi bi-instagram"></i></a>
      <a href="https://www.facebook.com/lanubefactory/"><i class="bi bi-facebook"></i></a>
      <a href="https://open.spotify.com/playlist/4x4o5uGuY0qy1Fid2wVvI6?go=1&sp_cid=eec33aa84b845bf466aa0c35f4db476a&utm_source=embed_player_p&utm_medium=desktop&nd=1"><i class="bi bi-spotify"></i></a>
    </aside>
    </footer>
  );
}

export default Footer