import React from 'react';
import './App.css';
import Navb from './components/Navbar/Navb';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import Hall from './components/Hall/Hall';
import MadeInNube from './components/MadeInNube/MadeInNube';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Study from './components/Study/Study';
import AboutUs from './components/AboutUs/AboutUs';


function App() {
  return (
    <div>
      <Navb />
      <Home/>
      <AboutUs />
      <Hall />
      <Services />
      <Study />
      <MadeInNube />
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
