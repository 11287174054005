const alquiler = require('../utils/ServicesImages/servicios-alquiler.jpg')
const producción = require('../utils/ServicesImages/servicios-mezcla.jpg')
const dj = require("../utils/ServicesImages/servicios-dj.jpg");
const rodaje = require("../utils/ServicesImages/servicios-rodaje.jpg");
const clases = require("../utils/ServicesImages/servicios-clases.jpg");
const streaming = require("../utils/ServicesImages/servicios-streaming.jpg");

const services = [
  {
    title: "Alquiler de estudio",
    description:
      "La sala, especialmente acondicionada y acustizada para que puedas trabajar cómodamente junto a tu equipo, cuenta con equipamiento profesional de acuerdo a los estándares de calidad de la industria. El patio es el lugar ideal para una pausa y recargar energías..",
    img: alquiler,
  },
  {
    title: "Producción, Mezcla y Mastering",
    description:
      "Nos motiva que suenes bien y estamos al servicio de tus canciones. Desde arreglar un tema hasta sonar en Spotify, te tenemos cubierto. Ya sea tu primer tema o bien quieras traer tu single, tu EP o disco de larga duración. Vamos a tratar a tu material como si fuera el nuestro.",
    img: producción,
  },
  {
    title: "Alquiler Cabina de DJ y Sonido",
    description:
      "Nuestro amplio y luminoso espacio brinda una cabina Pioneer XDJ 700 + Mixer Rane 64 y sonido JBL. Vení a sonar como en la discoteca.",
    img: dj,
  },
  {
    title: "Locación y Rodaje",
    description:
      "Salón de más de 100 m2 apto para rodaje y fotografía con fondo infinito y croma. Cuenta con excelente luz natural, barra y heladera para catering.",
    img: rodaje,
  },
  {
    title: "Clases Coaching",
    description:
      "Encontra propuestas de formación, sea que recién estás empezando o que quieras apuntalar tu carrera profesional. Producción Musical, Ableton live, DJ digital",
    img: clases,
  },
  {
    title: "Streaming",
    description:
      "Contamos con un set de cámaras y micrófonos aptos para broadcastear en HD y dar forma a todo tipo de contenido multimedia y para plataformas. Gaming, música, DJ sets o todo tipo de performances.",
    img: streaming,
  },
];

export default services;